import React from 'react';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionBlog() {
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713536113-4-dobra-conta-mobile-1.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713536113-4-dobra-conta-mobile-1.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713536113-4-dobra-conta-mobile-1.jpg' },
  }]);

  return (
    <Box
      tag="section"
      className="SectionBlog"
      backgroundImage={imageSrcSet}
      backgroundPosition="center"
      backgroundSize="cover"
      minHeight={{ xs: '456px', md: '576px', lg: 'calc(100vh - 56px)' }}
      display="flex"
      alignItens="center"
      padding="12x 0"
    >
      <Grid>
        <Grid.Child
          gridColumn={{
            xs: '1 / -1',
            md: '1 / span 4',
            lg: '3 / span 6',
            xl: '3 / span 5',
          }}
          gridRow="1 / -1"
          alignSelf={{ lg: 'center' }}
        >
          <Box paddingVertical={{ xs: '6x', md: '10x' }}>
            <Typography
              variant="heading2"
              color="white.default"
              marginBottom="8x"
              whiteSpace="pre-line"
            >
              Conheça nossa central de informações em caso de emergências.
            </Typography>
            <Link
              variant="action"
              href="https://blog.nubank.com.br/sos-nu/"
              typographyProps={{ color: 'white.default' }}
            >
              Conheça o SOS Nu
            </Link>
          </Box>
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default React.memo(SectionBlog);
